.location {
    border-radius: 20px 20px 20px 20px;
    margin: 0px auto;
    width: 95%;
    padding: 10px 10px;
    box-sizing: border-box;
    background-color: #ffffff;
}

.location_logo {
    width: auto;
    height: auto;
    text-align:center;
}

.logo-img {
    max-width: 400px;
    /*max-width: 100%;*/
    max-height: 192px;
    /*height: auto;*/
}

.location_title {
    margin: 0 auto 20px;
    max-width: 521px;
}

.location_title_name {
    font-weight: 700;
    text-align: center;
    margin-block-start: 0;
}

.location_title_name_only {
    font-weight: 700;
    text-align: center;
}

.location_container {
    padding-bottom: 20px;
}

.location_input_search {
    position: relative;
}

.search_icon {
    display: inline-block;
    height: 24px;
    position: absolute;
    width: 24px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.clear_icon {
    display: inline-block;
    height: 18px;
    position: absolute;
    width: 18px;
    left: 20px;
    top: 50%;
    left: 93%;
    transform: translateY(-50%);
    cursor: pointer;
}

.hide_icon {
    display: none;
}

.image_icon {
    width: 100%;
    height: 100%;
}

.pac-target-input:not(:-webkit-autofill) {
    animation-name: endBrowserAutofill;
}

.location_input_search > div > input {
    background: rgb(245, 245, 245);
    border: none;
    border-radius: 8px;
    padding: 16px 32px 16px 48px;
    width: 100%;
    box-sizing: border-box;
}

.location_container_div > button {
    width: 100%;
    margin-top: 15px;
}

.location_button {
    background: rgb(248, 109, 100);
    border: none;
    border-radius: 8px;
    padding: 16px 16px 16px 48px;
    position: relative;
    flex: 1 1 0%;
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.location_button_icon {
    display: inline-block;
    height: 24px;
    position: absolute;
    width: 24px;
    top: 50%;
    transform: translateY(-50%);
    left: calc(50vw - 85px);
}

.location_list {
    max-width: 700px;
    margin: auto;
}

.location_view {
    padding: 24px 0px;
    border-top: 1px solid rgb(224, 224, 224);
}

.location_view:last-child {
    border-bottom: 1px solid rgb(224, 224, 224);
}

.location_view_link {
    color: rgb(34, 34, 34);
    text-decoration: none;
    display: flex;
}

.location_image {
    display: flex;
    height: fit-content;
    position: relative;
    margin-right: 16px;
}

.location_image_style {
    border-radius: 12px;
    width: 90px;
    height: 90px;
    object-fit: cover;
}

.location_image_status {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.65);
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.location_image_status_text {
    width: 100%;
    text-align: center;
    opacity: 1;
}

.location_info {
    flex: 1 1 0%;
}

.location_info_header {
    display: block;
    margin-bottom: 4px;
}

.location_info_header_text {
    font-weight: 700;
}

.location_info_header_dollar {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 700;
}

.location_info_container {
    display: block;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
}

.store_info > :not(:last-child) {
    margin-bottom: 8px;
}

.store_info_closed {
    color: rgb(214, 64, 47);
    font-weight: bold;
    font-size: 0.775rem;
}

.store_info_opened {
    color: rgb(81, 150, 71);
    font-weight: bold;
    font-size: 0.775rem;
}

.store_info_address {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
}

.order_type {
    display: flex;
}

.order_type > :not(:last-child) {
    margin-right: 18px;
}

.order_type_info {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.order_type_image {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.store_info_button {
    color: rgb(255, 255, 255);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4;
    padding: 10px 30px;
    background: rgb(248, 109, 100);
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}

.autocomplete-dropdown-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    position: absolute;
    z-index: 1000;
    border-radius: 0 0 2px 2px;
}

.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999
}
.pac-item:hover {
    background-color: #fafafa
}
.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe
}
.pac-matched {
    font-weight: 700
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search {
    background-position: -1px -1px
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px
}
.pac-icon-marker {
    background-position: -1px -161px
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}
.pac-placeholder {
    color: gray
}

.error_message {
    padding: 0.5em 0px;
    color: rgb(183, 28, 28);
}

.location_footer {
    padding: 50px 15px 50px;
    text-align: center;
}

.location_footer_div {
    margin-bottom: 10px;
}

.location_footer_text {
    text-align: inherit;
    font-weight: inherit;
    line-height: 1.5;
    font-size: 14px;
}

.location_footer_link {
    text-align: inherit;
    font-weight: inherit;
    line-height: 1.5;
    color: rgb(117, 117, 117);
    text-decoration: none;
    font-size: 14px;
}

.location_footer_line {
    margin-left: 10px;
    margin-right: 10px;
}

.show_only_mobile {
    display: block;
}

.location_fontsize {
    font-size: 0.8rem;
}

h1 {
    font-size: 1.5rem;
}

h6 {
    font-size: 1rem;
}

textarea:focus, input:focus{
    outline-color: #000;
}

@media (min-width: 767px) {
    h1 {
        font-size: 2.125rem;
    }
    h6 {
        font-size: 1.25rem;
    }
    .location_title {
        margin: 0 auto 30px;
    }
    .location {
        width: 100%;
        max-width: 800px;
    }
    .location_info_header_dollar {
        font-size: 1.25rem;
    }
    .location_container {
        max-width: 600px;
        padding: 0px 53px 30px;
        margin: 0px auto;
    }
    .location_container_div {
        display: flex;
        gap: 20px;
    }
    .location_container_div > div:first-child {
        flex: 1 1 0%;
    }
    .location_container_div > button {
        margin-top: 0px;
    }
    .location_button {
        max-width: 202px;
    }
    .location_button_icon {
        left: 20px;
    }
    .location_image {
        margin-right: 40px;
    }
    .location_image_style {
        width: 130px;
        height: 130px;
    }
    .location_info_header {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .show_only_mobile {
        display: none;
    }
    .location_footer {
        padding-bottom: 20px;
    }
    .location_info_container {
        display: flex;
    }
    .location_fontsize {
        font-size: 1rem;
    }
    .store_info_closed {
        font-size: 1rem;
    }
    .store_info_opened {
        color: rgb(81, 150, 71);
        font-weight: bold;
        font-size: 1rem;
    }
}