body {
    background-color: rgb(241 241 241);
}

.container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    min-height: 900px;
}

@media (min-width: 767px) {
    .container {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}